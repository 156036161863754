import React, { useState } from "react";
import useEntityFetch from "./useEntityFetch";
import EntityDetailsDraw from "./EntityDetailsDraw";
import ContractPharmaciesTable from "./ContractPharmaciesTable";
import SubmissionsTable from "./SubmissionsTable";
import UsersTable from "./UsersTable";
import EntityDetailsHeader from "./EntityDetailsHeader";
import EnforcementLayerTable from "./EnforcementLayerTable";
import CeCpTable from "./CeCpTable";
import Overview from "./Overview";
import OviTable from "./OviTable";
import Tabs from "./Tabs";

const EntityDetailsIndex = (props) => {
  const [query, setQuery] = useState({ id_340b: props.initial_state_ce.id_340b });

  const resource = `/organization/${props.organization_id}/entities`;
  const entityDetails = useEntityFetch({ query, endpoint: `${resource}/covered_entity` });
  const pharmacies = useEntityFetch({ query, endpoint: `${resource}/contract_pharmacies` });
  const designations = useEntityFetch({ query, endpoint: `${resource}/designations` });

  const contractPharmaciesLoading = pharmacies.isLoading || designations.isLoading;
  const mapData = contractPharmaciesLoading ? [] : (pharmacies?.data || []).map((p) => p.geo_data);

  const tabs = [
    {
      id: "overview",
      label: "Overview",
      content: (
        <Overview
          query={query}
          organization_id={props.organization_id}
          contractPharmaciesLoading={contractPharmaciesLoading}
          mapData={mapData}
          entityDetails={entityDetails}
        />
      ),
      isDisabled: false,
    },
    {
      id: "contracted-pharmacies",
      label: "Contract Pharmacies",
      content: (
        <ContractPharmaciesTable
          pharmacies={pharmacies.data}
          key={query.id_340b}
          id_340b={query.id_340b}
          designations={designations.data}
          suspensions_path={props.suspensions_path}
          exceptions_path={props.exceptions_path}
          networks={props.networks}
          isLoading={contractPharmaciesLoading}
          chainEnforcement={props.chain_enforcement}
        />
      ),
      isDisabled: false,
    },
    {
      id: "submissions",
      label: "Submissions",
      content: (
        <SubmissionsTable
          query={query}
          resource={resource}
          tableColumns={props.table_columns}
        />
      ),
      isDisabled: false,
    },
    {
      id: "users",
      label: "Users",
      content: <UsersTable query={query} resource={resource} />,
      isDisabled: false,
    },
    {
      id: "enforcement-layer",
      label: "Enforcement Layer",
      content: <EnforcementLayerTable query={query} resource={resource} />,
    },
    {
      id: "ce-cp",
      label: "Ce / Cp",
      content: <CeCpTable query={query} resource={resource} />,
    },
    {
      id: "ovi",
      label: "Communications",
      content: (
        <OviTable
          query={query}
          resource={resource}
          id_340b={props.initial_state_ce.id_340b}
          organization_id={props.organization_id}
        />
      ),
      isDisabled: !props.is_ovi_client,
    },
  ];

  const handleCEChange = ({ value }) => {
    setQuery({ ...query, id_340b: value });
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="content__container content__container--no-padding">
        <EntityDetailsHeader entityDetails={entityDetails} />

        <div style={{ padding: "18px" }}>
          {/* TODO: Class can be reverted to dashboard__container when In-House WAC and CPs WAC are implemented in StatCards.jsx */}
          <Tabs tabs={tabs} initialActiveTabId="overview" />
        </div>
      </div>
      <EntityDetailsDraw
        onCoveredEntityEventEmit={(selectedOption) => handleCEChange(selectedOption)}
        initialEntity={{
          value: props.initial_state_ce.id_340b,
          label: props.initial_state_ce.name,
        }}
        organizationId={props.organization_id}
      />
    </div>
  );
};

export default EntityDetailsIndex;
