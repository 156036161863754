import React, { useEffect, useState } from "react";
import ReportLayout from "../ReportLayout";
import Table from "./ExceptionsTable";
import DownloadReport from "../Common/DownloadReport";
import ReportSearch from "../Common/ReportSearch";
import ReportDrawer from "../Common/ReportDrawer";
import NetworkFilter from "../Common/NetworkFilter";
import { search } from "../../utils/search";
import httpClient from "../../utils/httpClient";

import { MfrException, NetworkOption } from "../../types/models";

type ExceptionsIndexProps = {
  exceptions_path: string;
  exceptions_download_path: string;
  entity_path: string;
  filename: string;
  networks: Array<NetworkOption>;
};

const ExceptionsIndex = ({
  exceptions_path,
  exceptions_download_path,
  entity_path,
  filename,
  networks,
}: ExceptionsIndexProps) => {
  const [exceptions, setExceptions] = useState<Array<MfrException>>([]);
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0].value);
  const [searchTerms, setSearchTerms] = useState("");

  useEffect(() => {
    httpClient
      .get(exceptions_download_path)
      .then((res) => setExceptions(res.data));
  }, []);

  const filteredExceptions = selectedNetwork
    ? exceptions.filter((e) => e.network === selectedNetwork)
    : exceptions;

  const searchedExceptions = searchTerms
    ? search(filteredExceptions, searchTerms, ["entity_name", "id_340b"])
    : filteredExceptions;

  const handleExceptionDelete = (exceptionId) => {
    setExceptions(exceptions.filter((e) => e.id !== exceptionId));
  };

  const downloadComponent = (
    <DownloadReport
      reportName="exceptions"
      downloadPath={exceptions_download_path}
      downloadFilename={filename}
      downloadParams={{ network: selectedNetwork }}
    />
  );

  const networkFilter = (
    <NetworkFilter
      required={false}
      networks={networks}
      selectedNetwork={selectedNetwork}
      setSelectedNetwork={setSelectedNetwork}
    />
  );

  return (
    <ReportLayout
      title="Covered Entity Exceptions"
      body={
        <>
          <div className="table__actionbar table__actionbar--spacebetween">
            <ReportSearch setSearchTerms={setSearchTerms} />
          </div>
          <Table
            exceptions={searchedExceptions}
            exceptions_path={exceptions_path}
            entity_path={entity_path}
            handleExceptionDelete={handleExceptionDelete}
          />
        </>
      }
      aside={
        <ReportDrawer
          download={downloadComponent}
          filterComponents={[networkFilter]}
        />
      }
    />
  );
};

export default ExceptionsIndex;
