import React from "react";
import useEntityFetch from "./useEntityFetch";
import ClaimsTable from "../Reports/ClaimSubmissions/ClaimsDetailsTable";

const SubmissionsTable = ({ query, resource, tableColumns }) => {
  const { isLoading, data, error } = useEntityFetch({
    query,
    endpoint: `${resource}/submissions`,
  });

  return (
    <ClaimsTable
      claims={data}
      isLoading={isLoading}
      tableColumns={tableColumns}
    />
  );
};

export default SubmissionsTable;
